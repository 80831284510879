.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  width: 80%;
  max-width: 600px;
  /* max-height: 80%; */
  overflow: auto;
  opacity: 0;
  transform: scale(0.9);
  animation: slideUp 0.5s forwards;
}

@keyframes slideUp {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.3s, transform 0.3s;
}

.modal-close-button:hover {
  background-color: #f0f0f0;
  transform: scale(1.1);
}

.modal-close-button:before {
  content: "\00d7"; /* Unicode for 'x' character */
  font-size: 20px;
}

.modal-header {
  text-align: center;
  margin-bottom: 20px;
}

.modal-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.modal-description {
  font-size: 18px;
}

.modal-phone-link {
  color: var(--color-golden);
  text-decoration: underline;
}

.modal-divider {
  width: 100%;
  height: 2px;
  background-color: #ddd;
  margin: 20px 0;
}

.modal-image-container {
  display: flex;
  justify-content: center;
}

.modal-image {
  width: 100%;
  max-height: calc(80vh - 160px);
  height: auto;
  display: block;
  margin: 0 auto;
  transform: rotate(0deg);
  animation: rotateImage 3s forwards;
}

@keyframes rotateImage {
  0% {
    transform: rotate(-360deg) scale(0.5);
    opacity: 0;
  }
  100% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
}
